import { ServiceError } from 'lib/api/ServiceError'

export const cloudflareMitigation = (error: unknown) => {
  if (error instanceof ServiceError && error.code === 403) {
    const currentUrl = new URL(window.location.href)

    if (!currentUrl.searchParams.has('cf-mitigated')) {
      currentUrl.searchParams.set('cf-mitigated', Date.now().toString())

      window.location.href = currentUrl.toString()
    }
  }
}
