import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { CustomSelect } from 'components/atoms'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { formatNumber } from 'lib/format'
import Link from 'next/link'
import { QueryResult } from 'types/QueryResult'
import { Policies } from 'types/vault/Policies'
import { Paginated } from 'types/vault/v2/Paginated'
import { PremiumCurrency } from 'types/vault/v2/PremiumCurrency'
import { Token } from 'types/vault/v2/Token'
import { BIGTIME_TOKEN_ID, TIME_CRYSTAL_ID } from '../../../constants'
import { User } from '../../../types/vault/User'

interface CurrencyItemProps {
  name: string
  icon: string
  balance: number
  href: string
  selected: boolean
  disableLink: boolean
}

const getBlackIconUrl = (url: string) => {
  // TODO - We might need a standard way to get the black version of the icon
  if (url.endsWith('.svg')) return url.replace(/\.svg$/, '-black.svg')
  return url
}

const CurrencyItem = ({ name, icon, balance, href, selected, disableLink }: CurrencyItemProps) => (
  <Flex flexDir="row" cursor="pointer" py={1}>
    <Text
      fontWeight="700"
      color="gray.500"
      fontSize="12px"
      lineHeight="16px"
      mr={2}
      flex={1}
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
      title={name}
    >
      {selected && !disableLink ? <Link href={href}>{name}</Link> : <>{name}</>}
    </Text>
    <Flex w={{ base: undefined, md: selected ? undefined : '88px' }}>
      <Image
        display="flex"
        justify-content="center"
        align-items="center"
        gap="10px"
        width="16px"
        height="16px"
        src={selected ? icon : getBlackIconUrl(icon)}
        fallbackSrc={icon}
        mx={1}
        alt={name}
      />
      <Text
        fontWeight="700"
        color={selected ? 'white' : 'black'}
        fontSize="12px"
        lineHeight="16px"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        title={formatNumber(balance)}
      >
        {formatNumber(balance)}
      </Text>
    </Flex>
  </Flex>
)

const getCurrencyUrl = (currency: PremiumCurrency) => {
  switch (currency.type) {
    case 'tradeable':
      return `/wallet/premium-currencies/marketplace/${currency.id}?tab=buy`
    case 'sellable':
      return `/wallet/premium-currencies/${currency.id}`
    case 'convertible':
      return `/wallet/premium-currencies/${currency.id}/convert`
    default:
      return ''
  }
}

interface TokensWidgetProps {
  user: User
  policies: Policies
  tokenList?: QueryResult<Token>
  currencyList?: Paginated<PremiumCurrency>
}

export const TokensWidget = ({ user, policies, tokenList, currencyList }: TokensWidgetProps) => {
  const tokens = tokenList?.items ?? []
  const currencies = currencyList?.items ?? []
  const canConvert = user?.kyc?.status === 'success' || policies?.canUseOpenlootToken

  const [defaultTokenId, setDefaultTokenId] = useLocalStorage('default-token', BIGTIME_TOKEN_ID)
  const [defaultCurrencyId, setDefaultCurrencyId] = useLocalStorage(
    'default-currency',
    TIME_CRYSTAL_ID
  )

  return (
    <Box
      zIndex="sticky"
      pos="absolute"
      right={{ base: undefined, md: 0 }}
      top={{ base: '75px', lg: '87px', '3xl': '108px' }}
      h={0}
    >
      <Flex
        direction="row"
        justifyContent={{ base: 'space-evenly', md: 'flex-end' }}
        mx={3}
        my={1}
        float={{ base: 'none', md: 'right' }}
        background="black"
        borderRadius="40px"
        border="1px solid"
        borderColor="whiteAlpha.400"
        px={3}
      >
        <CustomSelect
          position="left"
          options={tokens.map((token) => ({
            label: (
              <CurrencyItem
                key={token.tokenId}
                name={token.name}
                icon={token.icon}
                selected={false}
                disableLink={false}
                balance={user?.userTokens.find((item) => item.tokenId === token.id)?.balance ?? 0}
                href="/wallet/tokens"
              />
            ),
            id: token.id,
          }))}
          defaultSelectedIndex={tokens.findIndex((token) => token.id === defaultTokenId)}
          onSelect={({ id }) => {
            setDefaultTokenId(id)
          }}
          key={defaultTokenId}
        />
        <Box borderRight="1px solid" borderColor="whiteAlpha.400" mx={3} />
        <CustomSelect
          position="right"
          options={currencies.map((currency) => ({
            label: (
              <CurrencyItem
                key={currency.id}
                name={currency.name ?? ''}
                icon={currency.icon ?? ''}
                selected={false}
                disableLink={currency.type === 'convertible' && !canConvert}
                balance={
                  user?.premiumCurrencyBalances.find(
                    (item) => item.premiumCurrencyId === currency.id
                  )?.amount ?? 0
                }
                href={getCurrencyUrl(currency)}
              />
            ),
            id: currency.id,
          }))}
          defaultSelectedIndex={currencies.findIndex(
            (currency) => currency.id === defaultCurrencyId
          )}
          onSelect={({ id }) => {
            setDefaultCurrencyId(id)
          }}
          key={defaultCurrencyId}
        />
      </Flex>
    </Box>
  )
}
