import { Box, Flex, Heading, Link, Stack, Text } from '@chakra-ui/react'
import { formatDate } from 'lib/format'
import Head from 'next/head'
import { formatHtmlTitle } from 'utils/globals'
import { Breadcrumb, Button } from '../atoms'
import { Container } from './layout'

export const TermsOfUseOLRP = () => (
  <>
    <Head>
      <title key="title">{formatHtmlTitle('Terms of Use OLRP')}</title>
    </Head>
    <Container
      mt={{ base: '18px', md: '22px' }}
      pb="77px"
      baseMaxWidth="1280px"
      extraPaddingX="2px"
    >
      <Breadcrumb
        items={[{ label: 'Open Loot Rewards Points Program Terms', href: '/terms-of-use-olrp' }]}
      />
      <Text display="inline-block" mt={3} borderBottom="1px solid white">
        Last updated: {formatDate(new Date(2024, 10, 19))}
      </Text>
      <Heading color="white" fontSize={['30px', '40px', '50px']} mb={8} mt={6}>
        Open Loot Rewards Points Program Terms
      </Heading>
      <Box overflowY="auto" maxH="58vh">
        <Stack spacing={3} fontSize="xs" fontWeight="500" pb="30px">
          <Text>
            THESE REWARDS PROGRAM TERMS CONTAIN AN ARBITRATION AGREEMENT, WHICH WILL, WITH LIMITED
            EXCEPTION, REQUIRE YOU TO SUBMIT CLAIMS YOU HAVE AGAINST OPEN LOOT, LLC TO BINDING AND
            FINAL ARBITRATION. UNDER THE ARBITRATION AGREEMENT, (1) YOU WILL ONLY BE PERMITTED TO
            PURSUE CLAIMS AGAINST OPEN LOOT, LLC ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS
            MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING, AND (2) YOU WILL ONLY BE
            PERMITTED TO SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN
            INDIVIDUAL BASIS.
          </Text>
          <Text>
            NO PURCHASE NECESSARY TO ENTER OR WIN ANY CHALLENGE, OR TO CLAIM OPEN LOOT REWARDS
            POINTS (THE “REWARDS POINTS”) UNDER THE OPEN LOOT REWARDS POINTS PROGRAM. A PURCHASE
            WILL NOT INCREASE YOUR CHANCES OF WINNING.
          </Text>
          <Text>1. ACCEPTANCE OF TERMS.</Text>
          <Text>
            Open Loot LLC (“OL”) makes available the website openloot.com (the “Site”) and the Open
            Loot Rewards Points Program (“Rewards Program”). Please read these Terms (the “Rewards
            Program Terms”) carefully before agreeing to be part of the Rewards Program. By
            accessing or otherwise participation in the Rewards Program, you: (1) accept and agree
            to be bound by the Rewards Program Terms; (2) represent that you are at the legal age or
            over the legal age to be a part of the Rewards Program and the Site pursuant to Sections
            3 and 8 below; (3) consent to the collection, use, disclosure and other handling of
            information as described in our Privacy Policy, available at{' '}
            <Link href="/privacy-policy" target="_blank" textDecor="underline">
              https://openloot.com/privacy-policy
            </Link>
            , the terms of which are incorporated herein in their entirety, and (4) accept and agree
            to any additional terms, rules and conditions of participation issued by OL from time to
            time, including the Terms of Use available at{' '}
            <Link href="/terms-of-use" target="_blank" textDecor="underline">
              https://openloot.com/terms-of-use
            </Link>
            , which shall apply for all matters not specifically described herein and are
            incorporated by reference into the Rewards Program Terms. In the event of contradiction
            between the Rewards Program Terms and the Terms of Use, the Terms of Use shall prevail.
            If you do not agree to the Rewards Program Terms, then you must not access or agree to
            be part of the Rewards Program and/or the Site. OL has no continued obligation to
            operate the Site or the Rewards Program in the future, at its exclusive discretion, with
            no liability whatsoever in connection thereto. The Open Loot Points shall be
            exchangeable for $OL, when available, pursuant to the conditions to be communicated in
            the future by OL.
          </Text>
          <Text>2. ELIGIBILITY.</Text>
          <Text>
            You hereby represent and warrant that you are fully able and competent to enter into the
            terms, conditions, obligations, affirmations, representations and warranties set forth
            in these Rewards Program Terms and to abide by and comply with these Rewards Program
            Terms. In order to qualify for the Rewards Program:
          </Text>
          <Text>2.1. You need to create a Marketplace Account with OL (www.openloot.com).</Text>
          <Text>
            2.2. In order to exchange the Rewards Points for $OL, you have to complete the KYC
            pursuant to Section 4.6 of the Terms of Use{' '}
            <Link href="/terms-of-use" target="_blank" textDecor="underline">
              https://openloot.com/terms-of-use
            </Link>
            .
          </Text>
          <Text>2.3. You must connect your X Account to OL.</Text>
          <Text>2.3. Rewards Points can only be obtained by way of:</Text>
          <Text>
            Mystery Boxes, containing Rewards Points. These Mystery Boxes can only be received as a
            reward, cannot be purchased and cannot be transferred. The content of the Mystery Boxes
            and the ratio is determined by OL at its sole discretion. Airdrops of Rewards Points As
            a reward within the Games available in the Marketplace.
          </Text>
          <Text>
            2.4. The actions needed to receive Rewards Points shall be communicated by OL, and are
            subject to modification at OL's exclusive discretion. Any action necessary to receive
            Rewards Points is subject to the Terms of Use. In particular, you must abstain from
            using automated bots, spamming The amount of Rewards Points earned by you will determine
            the reward tier (the “Reward Tier”) in which you will be positioned. The more Rewards
            Points you obtain the higher the Reward Tier you could be positioned in, and the more
            $OL you could receive by way of exchanging the Rewards Points. The information on how to
            obtain Rewards Points and the Reward Tiers is available at{' '}
            <Link
              href="https://wiki.openloot.com/ol-token/open-loot-reward-points-olrp"
              target="_blank"
              textDecor="underline"
            >
              https://wiki.openloot.com/ol-token/open-loot-reward-points-olrp
            </Link>{' '}
            and will be updated from time to time.
          </Text>
          <Text>
            2.5. The redemption of Rewards Points for $OL is subject to a cooling off period of up
            to ten (10) days since the obtention of the Rewards Points.
          </Text>
          <Text>3. REPRESENTATIONS AND RISKS.</Text>
          <Text>
            3.1 DISCLAIMER. You acknowledge and agree that your use of the Site and participation in
            the Rewards Program is at your own risk. The Site and the Rewards Program are provided
            on an “as is” and “as available” basis, without warranties of any kind, either express
            or implied, including, without limitation, implied warranties of merchantability,
            fitness for a particular purpose or non-infringement. You acknowledge and agree that OL
            has no control over, and no duty to take any action regarding: what effects the Rewards
            Program may have on you; how you may interpret or use the Rewards Program and the
            Rewards Points; or what actions you may take or fail to take as a result of having been
            exposed to the Rewards Program. You release OL from all liability for your inability to
            access the Site, the Rewards Points and the Rewards Program or any content therein.
          </Text>
          <Text>
            3.2 NO TRANSFERABILITY OF REWARDS POINTS You acknowledge that Rewards Points are not
            transferable and can only be exchanged for $OL subject to the terms and conditions to be
            informed by OL in the future.
          </Text>
          <Text>
            3.3 DISCRETIONALITY You acknowledge that OL shall decide at its sole discretion the
            awarding of Rewards Points.
          </Text>
          <Text>
            3.4 U.S. RESIDENTS You acknowledge that if you are a United States resident the Vault
            will not be available to exchange or receive custody of $OL. In order to redeem the
            Rewards Points, you will need to connect your external wallet.
          </Text>
          <Text>
            3.5 EXPIRATION OF REWARDS POINTS You acknowledge that the Rewards Points will expire
            within 48 months of their reception in your Marketplace Account. Any reward points not
            redeemed upon the expiration date will be lost without the right to make any claim.
          </Text>
          <Text>
            4. CHANGES. OL may make changes to these Terms from time to time. When OL makes changes,
            the updated Terms will be made available through the Site and update the “Last Updated”
            date at the beginning of these Terms accordingly. Please check these Terms periodically
            for changes. Any changes to the Terms will apply on the date that they are made, and
            your continued access to the Site and/or participation in the Rewards Program after the
            Terms have been updated will constitute your binding acceptance of the updates. If you
            do not agree to any revised Terms, you must not access the Site, and/or participate in
            the Rewards Program.
          </Text>
          <Text>5. INDEMNITY.</Text>
          <Text>
            You shall, and hereby do, release and indemnify, defend and hold harmless OL, and its
            parents, subsidiaries, affiliates and agencies, as well as the officers, directors,
            employees, shareholders and representatives of any of the foregoing entities, from and
            against any and all losses, liabilities, expenses, damages, costs (including attorneys'
            fees and court costs) claims or actions of any kind whatsoever related to, arising out
            of, or resulting from, the Site, and/or the Rewards Program, whether based on your use
            of the site or not, your violation of these Rewards Program Terms, and any of your acts
            or omissions. OL reserves the right, at its own expense, to assume exclusive defense and
            control of any matter otherwise subject to indemnification by you and, in such case, you
            agree to cooperate with OL in the defense of such matter.
          </Text>
          <Text>6. DISCLAIMERS.</Text>
          <Text>THIS SECTION 6 DOES NOT APPLY TO USERS BASED IN THE EU OR THE UK.</Text>

          <Text>
            6.1 YOU ACKNOWLEDGE AND AGREE THAT YOU ASSUME FULL RESPONSIBILITY FOR YOUR USE OF THE
            SITE AND/OR THE REWARDS PROGRAM. YOU ACKNOWLEDGE AND AGREE THAT ANY INFORMATION YOU SEND
            OR RECEIVE DURING YOUR USE OF THE SITE AND/OR PARTICIPATION IN THE REWARDS PROGRAM MAY
            NOT BE SECURE AND MAY BE INTERCEPTED OR LATER ACQUIRED BY UNAUTHORIZED PARTIES. YOU
            ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE SITE, AND/OR PARTICIPATION IN THE REWARDS
            PROGRAM IS AT YOUR OWN RISK. YOU ACKNOWLEDGE AND AGREE THAT THE SITE AND/OR THE REWARDS
            PROGRAM ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, WHETHER
            EXPRESS OR IMPLIED. YOU ACKNOWLEDGE AND AGREE THAT, TO THE FULLEST EXTENT PERMITTED BY
            APPLICABLE LAW, NEITHER OL, ITS AFFILIATES, DIRECTORS, OFFICERS AND EMPLOYEES NOR ITS
            SUPPLIERS OR LICENSORS WILL BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL,
            SPECIAL, CONSEQUENTIAL, PUNITIVE, EXEMPLARY OR OTHER DAMAGES OF ANY KIND, INCLUDING
            WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER TANGIBLE OR
            INTANGIBLE LOSSES OR ANY OTHER DAMAGES BASED ON CONTRACT, TORT, STRICT LIABILITY,
            INFRINGEMENT OF INTELLECTUAL PROPERTY OR THEFT OR MISAPPROPRIATION OF PROPERTY OR ANY
            OTHER THEORY (EVEN IF OL HAD BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING
            FROM THE SITE AND/OR THE REWARDS PROGRAM; THE USE OR THE INABILITY TO USE THE SITE,
            AND/OR TO PARTICIPATE IN THE REWARDS PROGRAM; UNAUTHORIZED ACCESS TO OR ALTERATION OF
            YOUR TRANSMISSIONS OR DATA; STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SITE AND/OR
            THE REWARDS PROGRAM; ANY ACTIONS OL TAKES OR FAILS TO TAKE AS A RESULT OF COMMUNICATIONS
            YOU SEND; HUMAN ERRORS; TECHNICAL MALFUNCTIONS; FAILURES, INCLUDING PUBLIC UTILITY OR
            TELEPHONE OUTAGES; OMISSIONS, INTERRUPTIONS, LATENCY, DELETIONS OR DEFECTS OF ANY DEVICE
            OR NETWORK, PROVIDERS, OR GAME (INCLUDING, BUT NOT LIMITED TO, THOSE THAT DO NOT PERMIT
            PARTICIPATION IN THE GAME); ANY INJURY OR DAMAGE TO COMPUTER EQUIPMENT; INABILITY TO
            FULLY ACCESS THE SITE, THE PROGRAM OR ANY OTHER WEBSITE; THEFT, TAMPERING, DESTRUCTION,
            OR UNAUTHORIZED ACCESS TO, IMAGES OR OTHER CONTENT OF ANY KIND; DATA THAT IS PROCESSED
            LATE OR INCORRECTLY OR IS INCOMPLETE OR LOST; TYPOGRAPHICAL, PRINTING OR OTHER ERRORS,
            OR ANY COMBINATION THEREOF;. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN
            WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL
            DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
          </Text>
          <Text>
            6.2 OL HEREBY EXPRESSLY DISCLAIMS, WAIVES, RELEASES AND RENOUNCES ALL WARRANTIES,
            EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NON INFRINGEMENT IN CONNECTION WITH THE
            REWARDS POINTS.
          </Text>
          <Text>
            6.3 WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, OL, ITS AFFILIATES, DIRECTORS,
            OFFICERS AND EMPLOYEES , AND LICENSORS DO NOT REPRESENT OR WARRANT TO YOU THAT: (I) YOUR
            ACCESS TO OR USE OF THE SITE AND/OR THE REWARDS PROGRAM WILL MEET YOUR REQUIREMENTS,
            (II) YOUR ACCESS TO OR USE OF THE SITE AND/OR THE REWARDS PROGRAM WILL BE UNINTERRUPTED,
            TIMELY, SECURE OR FREE FROM ERROR, (III) USAGE DATA PROVIDED THROUGH THE SITE AND/OR THE
            REWARDS PROGRAM WILL BE ACCURATE OR COMPLETE, (III) THE SITE, THE REWARDS PROGRAM OR ANY
            CONTENT, SERVICES, OR FEATURES MADE AVAILABLE ON OR THROUGH THE SITE, AND/OR THE REWARDS
            PROGRAM ARE FREE OF BUGS, VIRUSES OR OTHER HARMFUL COMPONENTS, OR (IV) THAT ANY DATA
            THAT YOU DISCLOSE WHEN YOU USE THE SITE, AND/OR THE REWARDS PROGRAM WILL BE SECURE.
          </Text>
          <Text>
            6.4 YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE
            OVER THE INTERNET, AND AGREE THAT OL, ITS AFFILIATES, DIRECTORS, OFFICERS AND EMPLOYEES
            HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR
            GROSS NEGLIGENCE.
          </Text>
          <Text>
            6.5 OL, ITS AFFILIATES, DIRECTORS, OFFICERS AND EMPLOYEES WILL NOT BE RESPONSIBLE OR
            LIABLE TO YOU FOR ANY LOSSES YOU INCUR AS THE RESULT OF YOUR USE OF THE ETHEREUM NETWORK
            OR FORTMATIC, METAMASK OR ANY OTHER ELECTRONIC WALLET, INCLUDING BUT NOT LIMITED TO ANY
            LOSSES, DAMAGES OR CLAIMS ARISING FROM: (A) USER ERROR, SUCH AS FORGOTTEN PASSWORDS OR
            INCORRECTLY CONSTRUED SMART CONTRACTS OR OTHER TRANSACTIONS; (B) SERVER FAILURE OR DATA
            LOSS; (C) CORRUPTED WALLET FILES; (D) UNAUTHORIZED ACCESS OR ACTIVITIES BY THIRD
            PARTIES, INCLUDING BUT NOT LIMITED TO THE USE OF VIRUSES, PHISHING, BRUTEFORCING OR
            OTHER MEANS OF ATTACK AGAINST THE SITE, THE PROGRAM, ETHEREUM NETWORK, OR FORTMATIC,
            METAMASK OR OTHER ELECTRONIC WALLET.
          </Text>
          <Text>
            6.6. OL IS NOT RESPONSIBLE FOR LOSSES DUE TO BLOCKCHAIN OR FORTMATIC, METAMASK OR OTHER
            ELECTRONIC WALLET, INCLUDING BUT NOT LIMITED TO LATE REPORT BY DEVELOPERS OR
            REPRESENTATIVES (OR NO REPORT AT ALL) OF ANY ISSUES WITH THE BLOCKCHAIN, INCLUDING
            FORKS, TECHNICAL NODE ISSUES, OR ANY OTHER ISSUES HAVING FUND LOSSES AS A RESULT.
          </Text>
          <Text>7. LIMITATION OF LIABILITY.</Text>
          <Text>
            7.1 YOU UNDERSTAND AND AGREE THAT OL, ITS AFFILIATES, OFFICERS, EMPLOYEES, DIRECTORS,
            AND LICENSORS WILL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
            INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR, HOWSOEVER
            CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF
            PROFITS (WHETHER INCURRED DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR BUSINESS
            REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR ANY
            OTHER INTANGIBLE LOSS, EVEN IF OL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </Text>
          <Text>
            7.2 YOU AGREE THAT OUR TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING
            OUT OF OR RELATING TO THESE TERMS OR YOUR ACCESS TO OR USE OF (OR YOUR INABILITY TO
            ACCESS OR USE) ANY PORTION OF THE SITE, AND/OR PARTICIPATE IN THE REWARDS PROGRAM,
            WHETHER IN CONTRACT, TORT, STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, IS LIMITED TO
            THE GREATER OF (A) THE AMOUNTS YOU ACTUALLY PAID US UNDER THESE TERMS IN THE 12 MONTH
            PERIOD PRECEDING THE DATE THE CLAIM AROSE, OR (B) $100.
          </Text>
          <Text>
            7.3 YOU ACKNOWLEDGE AND AGREE THAT OL HAS MADE THE SITE, AND/OR THE REWARDS PROGRAM
            AVAILABLE TO YOU AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE WARRANTY DISCLAIMERS
            AND LIMITATIONS OF LIABILITY SET FORTH HEREIN, WHICH REFLECT A REASONABLE AND FAIR
            ALLOCATION OF RISK BETWEEN THE PARTIES AND FORM AN ESSENTIAL BASIS OF THE BARGAIN
            BETWEEN US. OL WOULD NOT BE ABLE TO PROVIDE THE USE OF THE SITE, AND/OR THE REWARDS
            PROGRAM TO YOU WITHOUT THESE LIMITATIONS.
          </Text>
          <Text>
            7.4 SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR
            CONSEQUENTIAL DAMAGES, AND SOME JURISDICTIONS ALSO LIMIT DISCLAIMERS OR LIMITATIONS OF
            LIABILITY FOR PERSONAL INJURY FROM CONSUMER PRODUCTS, SO THE ABOVE LIMITATIONS MAY NOT
            APPLY TO PERSONAL INJURY CLAIMS.
          </Text>
          <Text>
            8. GOVERNING LAW These Rewards Program Terms are governed by the laws of the State of
            Delaware, without regard to conflict of law provisions.
          </Text>
          <Text>9. ARBITRATION AGREEMENT</Text>
          <Text>PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS.</Text>
          <Text>
            Agreement to Arbitrate. This Section is referred to in these Rewards Program Terms as
            the “Arbitration Agreement”. You agree that any and all disputes or claims that have
            arisen or may arise between you and OL, whether arising out of or relating to these
            Rewards Program Terms, any Rewards Program, your participation in any Rewards Program,
            the Rewards Points, acceptance, possession, use or misuse of the Rewards Points
            (including any alleged breach thereof), any advertising, any aspect of the relationship
            between us, shall be resolved exclusively through final and binding arbitration, rather
            than a court, in accordance with the terms of this Arbitration Agreement, except that
            you may assert individual claims in small claims court, if your claims qualify. Further,
            this Arbitration Agreement does not preclude you from bringing issues to the attention
            of federal, state, or local agencies, and such agencies can, if the law allows, seek
            relief against us on your behalf. You agree that, by participating in any Rewards
            Program, you and OL are each waiving the right to a trial by jury or to participate in a
            class action. Your rights will be determined by a neutral arbitrator, not a judge or
            jury. The Federal Arbitration Act governs the interpretation and enforcement of this
            Arbitration Agreement. Prohibition of Class and Representative Actions and
            Non-Individualized Relief. YOU AND OL AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE
            OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
            PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING. UNLESS BOTH YOU AND OL AGREE
            OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON'S OR PARTY'S
            CLAIMS AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, OR
            CLASS PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE,
            AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO
            THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY'S INDIVIDUAL CLAIM(S).
            Pre-Arbitration Dispute Resolution. OL is always interested in resolving disputes
            amicably and efficiently, and most concerns can be resolved quickly and to the
            participant's satisfaction by emailing admin@openloot.com. If such efforts prove
            unsuccessful, a party who intends to seek arbitration must first send to the other, by
            certified mail, a written Notice of Dispute (“Notice”). The Notice to OL should be sent
            to 1000 Brickell Avenue, Suite #715, PMB 117, Miami, Florida, 33131, (“Notice Address”).
            The Notice must (i) describe the nature and basis of the claim or dispute and (ii) set
            forth the specific relief sought. If OL and you do not resolve the claim within sixty
            (60) calendar days after the Notice is received, you or OL may commence an arbitration
            proceeding. During the arbitration, the amount of any settlement offer made by OL or you
            shall not be disclosed to the arbitrator until after the arbitrator determines the
            amount, if any, to which you or OL is entitled. Arbitration will be conducted by a
            neutral arbitrator in accordance with the American Arbitration Association's (“AAA”)
            rules and procedures, including the AAA's Supplementary Procedures for Consumer-Related
            Disputes (collectively, the “AAA Rules”), as modified by this Arbitration Agreement. For
            information on the AAA, please visit its website,{' '}
            <Link href="http://www.adr.org" target="_blank" textDecor="underline">
              http://www.adr.org
            </Link>
            . Information about the AAA Rules and fees for consumer disputes can be found at the
            AAA's consumer arbitration page,{' '}
            <Link
              href="http://www.adr.org/consumer_arbitrations"
              target="_blank"
              textDecor="underline"
            >
              http://www.adr.org/consumer_arbitration
            </Link>
            . If there is any inconsistency between any term of the AAA Rules and any term of this
            Arbitration Agreement, the applicable terms of this Arbitration Agreement will control
            unless the arbitrator determines that the application of the inconsistent Arbitration
            Agreement terms would not result in a fundamentally fair arbitration. The arbitrator
            must also follow the provisions of these Rewards Program Terms as a court would. All
            issues are for the arbitrator to decide, including, but not limited to, issues relating
            to the scope, enforceability, and arbitrability of this Arbitration Agreement. Although
            arbitration proceedings are usually simpler and more streamlined than trials and other
            judicial proceedings, the arbitrator can award the same damages and relief on an
            individual basis that a court can award to an individual under these Rewards Program
            Terms and applicable law. Decisions by the arbitrator are enforceable in court and may
            be overturned by a court only for very limited reasons. Unless OL and you agree
            otherwise, any arbitration hearings will take place in a reasonably convenient location
            for both parties with due consideration of their ability to travel and other pertinent
            circumstances. If the parties are unable to agree on a location, the determination shall
            be made by AAA. If your claim is for $10,000 or less, OL agrees that you may choose
            whether the arbitration will be conducted solely on the basis of documents submitted to
            the arbitrator, through a telephonic hearing, or by an in-person hearing as established
            by the AAA Rules. If your claim exceeds $10,000, the right to a hearing will be
            determined by the AAA Rules. Regardless of the manner in which the arbitration is
            conducted, the arbitrator shall issue a reasoned written decision sufficient to explain
            the essential findings and conclusions on which the award is based. Costs of
            Arbitration. Payment of all filing, administration, and arbitrator fees (collectively,
            the “Arbitration Fees”) will be governed by the AAA Rules, unless otherwise provided in
            this Arbitration Agreement. If the value of the relief sought is $75,000 or less, at
            your request, OL will pay all Arbitration Fees. If the value of relief sought is more
            than $75,000 and you are able to demonstrate to the arbitrator that you are economically
            unable to pay your portion of the Arbitration Fees or if the arbitrator otherwise
            determines for any reason that you should not be required to pay your portion of the
            Arbitration Fees, OL will pay your portion of such fees. In addition, if you demonstrate
            to the arbitrator that the costs of arbitration will be prohibitive as compared to the
            costs of litigation, OL will pay as much of the Arbitration Fees as the arbitrator deems
            necessary to prevent the arbitration from being cost-prohibitive. Finally, if the value
            of the relief sought is $75,000 or less, OL will pay reasonable attorneys' fees should
            you prevail. OL will not seek attorneys' fees from you. But, if you initiate an
            arbitration in which you seek more than $75,000 in relief, the payment of attorneys'
            fees will be governed by the AAA Rules.
          </Text>
          <Text>9. TERMINATION AND SUSPENSION.</Text>
          <Text>
            You have a right to terminate your participation in the Rewards Program. OL may
            terminate or suspend all or part of the Site and/or the Rewards Program and your access
            to the Site and/or the Rewards Program immediately, without prior notice or liability.
            You agree that any suspension or termination of your access to the Site and/or the
            Rewards Program may be without prior notice, and that OL will not be liable to you or to
            any third party for any such suspension or termination. If OL suspends or terminates
            your participation in the Rewards Program due to your breach of these Terms or any
            suspected fraudulent, abusive, or illegal activity, then termination of these Terms will
            be in addition to any other remedies OL may have at law or in equity, including
            termination of your Marketplace Account. The following provisions of these Terms survive
            any termination of these Terms: REPRESENTATIONS AND RISKS; INDEMNITY; DISCLAIMERS;
            LIMITATION ON LIABILITY; TERMINATION AND SUSPENSION.
          </Text>
          <Text>10. COMPANY INFORMATION.</Text>
          <Text>Open Loot, LLC</Text>
          <Text>16192 Coastal Highway, Lewes, Delaware 19958, Sussex County, United States</Text>

          <Text>
            NOTICE The Company reserves the right to prosecute and seek damages against any
            individual who attempts to deliberately undermine the proper operation of the Rewards
            Program in violation of these Rewards Program Terms and/or criminal and/or civil law.
          </Text>
          <Text>Open Loot, and the associated logos are trademarks of OL.</Text>
        </Stack>
      </Box>

      <Flex
        mt={5}
        direction="column"
        width="100%"
        height="100%"
        justifyContent="flex-end"
        borderTop="1px"
        borderColor="seprator"
      >
        <Link
          href="/"
          alignSelf="flex-end"
          display="inline-flex"
          _hover={{
            textDecoration: 'none',
          }}
        >
          <Button mt="6" variant="light-outline" cursor="pointer">
            Back
          </Button>
        </Link>
      </Flex>
    </Container>
  </>
)
