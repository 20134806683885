import VaultApi from 'lib/api/VaultApi'
import { useQuery } from 'react-query'
import { ServiceError } from '../lib/api/ServiceError'
import { Policies } from '../types/vault/Policies'
import { useUser } from './useUser'

const fetchPolicies = (): Promise<Policies> =>
  VaultApi.doRequest({
    method: 'GET',
    url: '/market/me/policies',
  })
    .then((res) => res.json())
    .catch((e: ServiceError) => {
      if (e.code === 401) {
        return null
      }

      throw e
    })

export const usePolicies = () => {
  const { data } = useUser()

  const hasKyc = data?.kyc.status === 'success'

  return useQuery(['me', 'policies'], fetchPolicies, {
    staleTime: hasKyc ? 1000 * 60 * 3 : 30 * 1000,
  })
}
