import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

const RPC_URLS: { [chainId: number]: string } = {
  1: 'https://ethereum.publicnode.com',
  5: 'https://ethereum-goerli.publicnode.com',
  56: 'https://bscrpc.com	',
  137: 'https://polygon-rpc.com',
  195: 'https://xlayertestrpc.okx.com',
  80001: 'https://rpc-mumbai.matic.today',
  80002: 'https://rpc-amoy.polygon.technology/',
  11155111: 'https://rpc.sepolia.org',
}

const CHAIN_NAME: { [chainId: number]: string } = {
  5: 'Ethereum',
  56: 'BSC',
  137: 'Polygon',
  195: 'X Layer Testnet',
  196: 'X Layer',
  80001: 'Mumbai',
  80002: 'Polygon Amoy',
  11155111: 'Sepolia',
}

export const POSTCARDS_REDEMPTIONS_SUPPORTED_CHAIN_IDS =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? [56] : [11155111]

export const VIPPASSES_REDEMPTIONS_SUPPORTED_CHAIN_IDS =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? [56, 137] : [11155111]

export const NFT_TOKEN_SUPPORTED_CHAIN_IDS =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? [1, 56, 137]
    : [11155111, 5, 195, 84532, 80002]

export enum ConnectorNames {
  Injected = 'injected',
  WalletConnect = 'walletconnect',
  OKXWallet = 'okxwallet',
}

export function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider)

  library.pollingInterval = 12000

  return library
}

class OKXInjectedConnector extends InjectedConnector {
  private customProvider: any

  constructor(supportedChainIds: number[]) {
    super({ supportedChainIds })
  }

  override async activate(): Promise<any> {
    /* eslint no-implicit-globals: "error" */
    this.customProvider = (window as any).okxwallet

    // Explicitly use OKX Wallet's provider
    const accounts = await this.customProvider.request({ method: 'eth_requestAccounts' })
    const chainId = await this.customProvider.request({ method: 'eth_chainId' })

    return {
      provider: this.customProvider,
      chainId: parseInt(chainId, 16),
      account: accounts[0],
    }
  }
}

export function getConnectsByName(supportedChainIds: number[]): {
  [connectorName in ConnectorNames]: any
} {
  return {
    [ConnectorNames.Injected]: new InjectedConnector({ supportedChainIds }),
    [ConnectorNames.WalletConnect]: new WalletConnectConnector({
      rpc: RPC_URLS,
      infuraId: '4f5cb174002540189db79264d5106749',
      supportedChainIds,
      qrcode: true,
    }),
    [ConnectorNames.OKXWallet]: new OKXInjectedConnector(supportedChainIds),
  }
}

export function supportedNetworkListString(supportedChainIds: number[]): string {
  return supportedChainIds.map((chainId) => CHAIN_NAME[chainId]).join(', ')
}
