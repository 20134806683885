import { Box, Heading, SimpleGrid, useMediaQuery } from '@chakra-ui/react'
import { CardSkeleton } from 'components/atoms'
import { ItemCard } from 'components/organisms'
import { useSecondaryOptions } from 'hooks/useSecondaryOptions'
import { useTrackEffect } from 'hooks/useTracking'

export const RandomMarketplace = () => {
  const { data, isLoading } = useSecondaryOptions({
    sort: 'price:desc',
  })
  const items = data?.pages[0].items ?? []
  const [isMobile] = useMediaQuery('(max-width: 820px)')
  const spaces = items.slice(0, isMobile ? 6 : 5).sort((a, b) => a.minPrice - b.minPrice)
  useTrackEffect('rdr_home')

  return (
    <Box as="section">
      <Heading as="h3" size="xl" textShadow="heroText" textAlign="center" mb={5}>
        Marketplace
      </Heading>
      <SimpleGrid
        mt={[2.5, 4, 5, 6, 8, '35px']}
        columns={[2, null, 3, null, 4, 5]}
        gap={{ base: 3, md: 5, '2xl': 7, '3xl': '35px' }}
      >
        {spaces.map((listingItemMetadata, index) => (
          <ItemCard key={index} listingItemMetadata={listingItemMetadata} />
        ))}
        {isLoading && <CardSkeleton numCards={5} />}
      </SimpleGrid>
    </Box>
  )
}
